import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationDto } from '../dtos/notification.dto';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private apiUrl = environment.apiUrl + '/api/notification';

  public notificationSubject = new BehaviorSubject<NotificationDto[]>([]);

  public notificationObs = this.notificationSubject.asObservable();

  private unreadCountSubject = new BehaviorSubject<number>(0);
  public unreadCount$ = this.unreadCountSubject.asObservable();

  constructor(private http: HttpClient) { }

  getNotifications(): Observable<NotificationDto[]> {
    return this.http.get<NotificationDto[]>(`${this.apiUrl}`);
  }

  markAllAsReadd(): Observable<NotificationDto[]> {
    return this.http.put<NotificationDto[]>(`${this.apiUrl}/mark-all-as-read`, {});
  }

  markAllAsRead(notificationIds: number[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/mark-all-as-read`, notificationIds);
  }

  deleteNotification(notificationId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/${notificationId}`);
  }

  clearAllNotifications(): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}`);
  }

  // ------------------------- Observable
  updateNotifications(notification: NotificationDto[]): void {
    try {
      console.log('Updating notification ', notification)
      // Update the object
      this.notificationSubject.next(notification);
      this.updateUnreadCount(); // Recalculate unread count
    } catch (error) {
      this.notificationSubject.next([]);
    }
  }

  /**
   * Update unread count in state
   */
  updateUnreadCount(): void {
    const unread = this.notificationSubject.getValue().filter(n => !n.hasRead).length;
    this.unreadCountSubject.next(unread);
  }

}
